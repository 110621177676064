<template>
	<div
		:class="[
			'cw-app-wrapper',
			'promotion',
			'order_wrap',
			isPreContract ? 'pre-contract' : ''
		]"
	>
		<!-- 상단 스태퍼 -->
		<div class="cw-app-module">
			<MStepper />
		</div>
		<!--// 상단 스태퍼 -->

		<!--contents-->
		<div class="cw-app-container">
			<!-- 상단 타이틀, 옵션 초기화 -->
			<div class="tit_area">
				<h2 class="tit_page">상품선택</h2>
				<v-btn class="right_btn btn_rechoice" @click="optionReset()">
					옵션 초기화
				</v-btn>
			</div>
			<!--// 상단 타이틀, 옵션 초기화 -->

			<!-- 선택한 상품 정보 -->
			<div class="detail_item">
				<div class="flex_row">
					<div class="pic_area">
						<img :src="`${prd.color_file_path}`" :alt="prd.leader_model_nm" />
					</div>
					<div class="txt_area">
						<h3 class="tit">{{ prd.leader_model_nm }}</h3>
						<p class="sub_tit">{{ prd.prod_nm }}, {{ prd.erp_cd }}</p>
					</div>
				</div>

				<div class="count_box">
					<div class="mb-0">
						<dl class="price_area" v-if="showPrice('2')">
							<dt>등록비</dt>
							<dd>
								{{ (optionAppliedPrice.regPrice * prdQuantity) | comma }}원
							</dd>
						</dl>
						<dl class="price_area" v-if="showPrice('2')">
							<dt>렌탈료</dt>
							<dd>
								{{
									(optionAppliedPrice.rentalPrice * prdQuantity) | comma
								}}원/월
							</dd>
						</dl>
						<dl class="price_area" v-if="showPrice('1')">
							<dt>일시불</dt>
							<dd>{{ (prd.price * prdQuantity) | comma }}원</dd>
						</dl>
					</div>

					<MNumericUpDown
						v-model="prdQuantity"
						:sellType="sellType.code"
						:btnDisabled="isPrdQuantity(sellType)"
					/>
				</div>

				<span class="txt_gray">
					렌탈/일시불 전용상품이거나 신용등급 기준 미충족의 경우, 판매유형
					선택이 제한됩니다.
				</span>
			</div>
			<!--// 선택한 상품 정보 -->

			<v-expansion-panels
				class="u-panel-bottomsheet"
				focusable
				v-model="openedPanel"
			>
				<!-- 판매구분 -->
				<v-expansion-panel class="accodion_select">
					<v-expansion-panel-header
						><h3 class="sub_tit">판매구분</h3>
						<p class="selected blue-primary">
							{{ sellType && sellType.text }}
						</p>
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<v-btn-toggle
							class="toggle_row"
							mandatory
							ref="sellType"
							v-model="sellTypeSelected"
						>
							<v-btn :style="'display:none;'"></v-btn>
							<v-btn
								v-for="(item, i) in sellTypeItems"
								class="btn_white"
								default
								large
								:style="item.ORD_ACTIVE === '1' ? '' : 'display: none'"
								:key="i"
								@click="sellTypeEvent(item, i)"
							>
								{{ item.text }}
							</v-btn>
						</v-btn-toggle>
					</v-expansion-panel-content>
				</v-expansion-panel>
				<!-- 판매구분 -->

				<!-- 홈체험2 주문번호 선택 -->
				<v-expansion-panel
					class="accodion_select"
					v-if="homePreOrderItems && homePreOrderItems.length > 0"
				>
					<v-expansion-panel-header>
						<h3 class="sub_tit">홈체험이력 선택</h3>
						<p class="selected primary--text">
							{{ homePreOrder && homePreOrder.HOME2_ORDER_NO }}
						</p>
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<v-btn-toggle
							class="toggle_row"
							mandatory
							v-model="homePreOrderSelected"
						>
							<v-btn :style="'display:none;'"></v-btn>
							<v-btn
								class="btn_field"
								v-for="(homePreOrderItem, homePreOrderIdx) in homePreOrderItems"
								:key="homePreOrderIdx"
								:disabled="isUsedHomePreOrder(homePreOrderItem)"
								@click="homePreOrderEvent(homePreOrderItem, homePreOrderIdx)"
							>
								<div class="box">
									<article class="desc">
										<p class="state">
											홈체험
										</p>
										<div class="info">
											<dl>
												<dt>주문번호</dt>
												<dd>{{ homePreOrderItem.HOME2_ORDER_NO }}</dd>
											</dl>
											<dl>
												<dt>설치일자</dt>
												<dd>{{ homePreOrderItem.FIRST_INST_DT }}</dd>
											</dl>
											<dl>
												<dt>혜택종료일</dt>
												<dd>{{ homePreOrderItem.END_DT }}</dd>
											</dl>
											<dl>
												<dt>반환상태</dt>
												<dd>{{ homePreOrderItem.RTN_YN }}</dd>
											</dl>
										</div>
									</article>
								</div>
							</v-btn>
						</v-btn-toggle>
					</v-expansion-panel-content>
				</v-expansion-panel>
				<!--// 홈체험2 주문번호 선택 -->

				<!-- 설치장소 선택 -->
				<v-expansion-panel
					class="accodion_select"
					v-if="chairMultiFacItems && chairMultiFacItems.length > 0"
				>
					<v-expansion-panel-header>
						<h3 class="sub_tit">설치장소 선택</h3>
						<p class="selected primary--text">
							{{ chairMultiFac && chairMultiFac.text }}
						</p>
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<v-btn-toggle class="toggle_row" mandatory>
							<template
								v-for="(chairMultiFac, chairMultiFacIdx) in chairMultiFacItems"
							>
								<MSelectButtonBottom
									v-if="chairMultiFacNextDepthCheck(chairMultiFac)"
									:key="`dd-${chairMultiFacIdx}`"
									:chairMultiFacSelected="chairMultiFacSelected"
									title="상세 설치장소"
									:items="
										getChairMultiFacData(`D${chairMultiFac.code}`).filter(
											v => v.isShow
										)
									"
									@item-selected="chairMultiFacEvent"
								>
									{{ chairMultiFac.text }}
								</MSelectButtonBottom>
								<v-btn
									v-else
									:key="`dt-${chairMultiFacIdx}`"
									class="btn_white"
									large
									@click="chairMultiFacEvent(chairMultiFac)"
								>
									{{ chairMultiFac.text }}
								</v-btn>
							</template>
						</v-btn-toggle>
					</v-expansion-panel-content>
				</v-expansion-panel>
				<!--// 설치장소 선택 -->

				<!-- 요금제 선택 -->
				<v-expansion-panel
					class="accodion_select"
					v-if="
						this.sellType.code === 'Z002' &&
							this.regiIdItems &&
							this.regiIdItems.length > 0
					"
				>
					<v-expansion-panel-header>
						<h3 class="sub_tit">요금제선택</h3>
						<p class="selected primary--text">
							{{ rerentalType && rerentalType.text }}
						</p>
					</v-expansion-panel-header>
					<v-expansion-panel-content class="bg_white no_border">
						<v-btn-toggle class="toggle_row" mandatory v-model="regiIdSelected">
							<v-btn
								v-for="(regiIdItem, regiIdIdx) in regiIdItems"
								class="btn_row justify-start"
								:key="regiIdIdx"
								@click="rerentalTypeEvent(regiIdItem, regiIdIdx)"
							>
								{{ regiIdItem.text }}
							</v-btn>
						</v-btn-toggle>
					</v-expansion-panel-content>
				</v-expansion-panel>
				<!--// 요금제 선택 -->

				<!-- 관리유형 -->
				<v-expansion-panel
					class="accodion_select"
					v-if="this.mngTypeItems && this.mngTypeItems.length > 0"
				>
					<v-expansion-panel-header>
						<h3 class="sub_tit">관리유형</h3>
						<p class="selected blue-primary">
							{{ manageType && manageType.text }}
						</p>
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<div class="panel_txt">
							방문하여 관리를 받거나 스스로 자가 관리를 하는 유형을 선택할 수
							있습니다.
						</div>
						<v-btn-toggle
							class="toggle_row"
							mandatory
							v-model="mngTypeSelected"
						>
							<v-btn :style="'display: none;'"></v-btn>
							<v-btn
								class="btn_white"
								default
								large
								v-for="(mngTypeItem, mngTypeIdx) in mngTypeItems"
								:key="mngTypeIdx"
								@click="manageTypeEvent(mngTypeItem, mngTypeIdx)"
							>
								<div class="flex_row">
									<div>{{ mngTypeItem.text }}</div>
									<div
										v-if="sellTypeCode !== 'Z001' && sellTypeCode !== 'Z002'"
									>
										<div class="text_orange">
											{{ mngTypeItem.price | comma }}원
										</div>
									</div>
								</div>
							</v-btn>
						</v-btn-toggle>
					</v-expansion-panel-content>
				</v-expansion-panel>
				<!-- 관리유형 -->

				<!-- 의무사용기간 -->
				<v-expansion-panel
					class="accodion_select"
					v-if="this.agreeTermItems && this.agreeTermItems.length > 0"
				>
					<v-expansion-panel-header>
						<h3 class="sub_tit">의무사용기간</h3>
						<p class="selected blue-primary">
							{{ agreeTermType && agreeTermType.text }}
						</p>
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<v-btn-toggle
							class="toggle_row"
							mandatory
							v-model="agreeTermSelected"
						>
							<v-btn :style="'display:none;'"></v-btn>
							<v-btn
								class="btn_white"
								default
								large
								v-for="(agreeTermItem, agreeTermIdx) in agreeTermItems"
								:key="agreeTermIdx"
								@click="agreeTermTypeEvent(agreeTermItem, agreeTermIdx)"
							>
								<div class="flex_row">
									<div>{{ agreeTermItem.text }}</div>
									<div class="text_orange">
										{{ agreeTermItem.MONTH_AMT | comma }}원/월
									</div>
								</div>
							</v-btn>
						</v-btn-toggle>
					</v-expansion-panel-content>
				</v-expansion-panel>
				<!--// 의무사용기간 -->

				<!-- 멤버십 -->
				<v-expansion-panel
					class="accodion_select"
					v-if="membershipItems && membershipItems.length > 0"
				>
					<v-expansion-panel-header>
						<h3 class="sub_tit">멤버십</h3>
						<p class="selected blue-primary">
							{{ membershipType && membershipType.text }}
							<span
								class=" text_orange ml-1"
								v-if="membershipType && membershipType.code !== ''"
							>
								+{{ membershipType && membershipType.MON_AMT | comma }}원/월
							</span>
						</p>
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<div class="panel_txt">
							무상 하트서비스/필터교환 서비스를 신청합니다.<br />
							의무사용기간 : 1년
						</div>
						<v-btn-toggle
							class="toggle_row"
							mandatory
							v-model="membershipSelected"
						>
							<v-btn
								class="btn_white"
								default
								large
								v-for="(membershipItem, membershipIdx) in membershipItems"
								:key="membershipIdx"
								@click="membershipTypeEvent(membershipItem, membershipIdx)"
							>
								<div class="flex_row">
									<div>{{ membershipItem.text }}</div>
									<div class=" text_orange3" v-if="membershipItem.code !== ''">
										+{{ membershipItem.MON_AMT | comma }}원/월
									</div>
								</div>
							</v-btn>
						</v-btn-toggle>
					</v-expansion-panel-content>
				</v-expansion-panel>
				<!--// 멤버십 -->

				<!-- 기존주문번호 선택 -->
				<v-expansion-panel
					class="accodion_select"
					v-if="preOrderItems && preOrderItems.length > 0"
				>
					<v-expansion-panel-header>
						<h3 class="sub_tit">기존계정 선택</h3>
						<p class="selected primary--text">
							{{ preOrder && preOrder.ORDER_NO }}
						</p>
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<v-btn-toggle
							class="toggle_row"
							mandatory
							v-model="preOrderSelected"
						>
							<v-btn :style="'display:none;'"></v-btn>
							<v-btn
								class="btn_field"
								v-for="(preOrderItem, preOrderIdx) in preOrderItems"
								:key="preOrderIdx"
								:disabled="
									preOrderItem.RESULT !== 'T' ||
										isUsedPreOrder(preOrderItem) ||
										preOrderItem.PRESUB === 'S' ||
										(orderType === 'N' &&
											['S', 'M', 'C'].includes(preOrderItem.PRESUB))
								"
								@click="perOrderEvent(preOrderItem, preOrderIdx)"
								:style="
									(orderType === 'S' &&
										preOrderItem.PRESUB !== '' &&
										preOrderItem.PRESUB !== 'C') ||
									(isPreContract && preOrderItem.PRESUB === 'C') ||
									orderType === 'N'
										? ''
										: 'display:none;'
								"
							>
								<div class="box">
									<article class="desc">
										<p
											class="state"
											v-if="
												preOrderItem.PRESUB !== '' &&
													preOrderItem.PRESUB !== 'C'
											"
										>
											{{
												preOrderItem.PRESUB === 'M'
													? '사전예약'
													: '영업S 사전예약'
											}}
										</p>
										<div class="display" v-if="preOrderItem.PRESUB === 'C'">
											<span class="state">사전계약 가능</span>
											<span class="type">
												{{
													preOrderItem.PRE_COUNT === '0'
														? 'M'
														: `M-${preOrderItem.PRE_COUNT}`
												}}
											</span>
										</div>
										<div class="info">
											<dl>
												<dt>주문번호</dt>
												<dd>{{ preOrderItem.ORDER_NO }}</dd>
											</dl>
											<dl>
												<dt>설치일자</dt>
												<dd>{{ preOrderItem.FIRST_INST_DT }}</dd>
											</dl>
											<dl>
												<dt>설치주소</dt>
												<dd>{{ preOrderItem.ADRC_TOTAL }}</dd>
											</dl>
											<dl>
												<dt>사용불가 사유</dt>
												<dd>{{ preOrderItem.MESSAGE }}</dd>
											</dl>
											<dl class="impact">
												<dt>{{ preOrderItem.CONTRACT_NM }}</dt>
												<dd>{{ preOrderItem.GOODS_NM }}</dd>
											</dl>
										</div>
									</article>
								</div>
							</v-btn>
						</v-btn-toggle>
					</v-expansion-panel-content>
				</v-expansion-panel>
				<!--// 기존주문번호 선택 -->

				<!-- 필터선택 -->
				<v-expansion-panel
					class="accodion_select"
					v-if="
						filterTypeItems.length > 0 ||
							filterTypeItems2.length > 0 ||
							filterTypeItems3.length > 0 ||
							filterTypeItems4.length > 0
					"
				>
					<v-expansion-panel-header
						><h3 class="sub_tit">필터선택</h3>
						<p class="selected">{{ filterSelectedCnt }}/{{ filterCnt }}</p>
					</v-expansion-panel-header>

					<v-expansion-panel-content>
						<!-- 선택 1 -->
						<h4 class="sub_tit_btn" v-if="filterTypeItems.length > 0">
							선택1
						</h4>
						<v-btn-toggle
							class="toggle_row"
							mandatory
							v-model="filterTypeSelected"
							v-if="filterTypeItems.length > 0"
						>
							<v-btn :style="'display: none;'"></v-btn>
							<v-btn
								v-for="(filterItem1, filterIdx1) in filterTypeItems"
								class="btn_white"
								default
								large
								:key="filterIdx1"
								@click="
									filterSelect(filterItem1, filterType, 'filter1', filterIdx1)
								"
							>
								{{ filterItem1.text }}
							</v-btn>
						</v-btn-toggle>
						<!--// 선택 1 -->

						<!-- 선택 2 -->
						<h4 class="sub_tit_btn" v-if="filterTypeItems2.length > 0">
							선택2
						</h4>
						<v-btn-toggle
							class="toggle_row"
							mandatory
							v-model="filterTypeSelected2"
							v-if="filterTypeItems2.length > 0"
						>
							<v-btn :style="'display:none;'"></v-btn>
							<v-btn
								class="btn_white"
								default
								large
								v-for="(filterItem2, filterIdx2) in filterTypeItems2"
								:key="filterIdx2"
								@click="
									filterSelect(filterItem2, filterType2, 'filter2', filterIdx2)
								"
							>
								{{ filterItem2.text }}
							</v-btn>
						</v-btn-toggle>
						<!--// 선택 2 -->

						<!-- 선택 3 -->
						<h4 class="sub_tit_btn" v-if="filterTypeItems3.length > 0">
							선택3
						</h4>
						<v-btn-toggle
							class="toggle_row"
							mandatory
							v-model="filterTypeSelected3"
							v-if="filterTypeItems3.length > 0"
						>
							<v-btn :style="'display:none;'"></v-btn>
							<v-btn
								class="btn_white"
								default
								large
								v-for="(filterItem3, filterIdx3) in filterTypeItems3"
								:key="filterIdx3"
								@click="
									filterSelect(filterItem3, filterType3, 'filter3', filterIdx3)
								"
							>
								{{ filterItem3.text }}
							</v-btn>
						</v-btn-toggle>
						<!--// 선택 3 -->

						<!-- 선택 4 -->
						<h4 class="sub_tit_btn" v-if="filterTypeItems4.length > 0">
							선택4
						</h4>
						<v-btn-toggle
							class="toggle_row"
							mandatory
							v-model="filterTypeSelected4"
							v-if="filterTypeItems4.length > 0"
						>
							<v-btn :style="'display:none;'"></v-btn>
							<v-btn
								class="btn_white"
								default
								large
								v-for="(filterItem4, filterIdx4) in filterTypeItems4"
								:key="filterIdx4"
								@click="
									filterSelect(filterItem4, filterType4, 'filter4', filterIdx4)
								"
							>
								{{ filterItem4.text }}
							</v-btn>
						</v-btn-toggle>
						<!--// 선택 4 -->
					</v-expansion-panel-content>
				</v-expansion-panel>
				<!--// 필터선택 -->

				<!-- 추가선택 -->
				<v-expansion-panel
					class="accodion_select"
					v-model="hangerTypeSelected"
					v-if="hangerTypeItems.length > 0 || hangerTypeItems2.length > 0"
				>
					<v-expansion-panel-header
						><h3 class="sub_tit">추가선택</h3>
						<p v-bind:class="[waitClass, activeClass]">
							{{ prodAddSelectCnt }}/{{ prodAddCnt }}
						</p>
					</v-expansion-panel-header>

					<v-expansion-panel-content>
						<!-- 선택 1 -->
						<h4 class="sub_tit_btn" v-if="hangerTypeItems.length > 0">선택1</h4>
						<v-btn-toggle
							class="toggle_row"
							mandatory
							v-model="hangerTypeSelected"
							v-if="hangerTypeItems.length > 0"
						>
							<v-btn :style="'display:none;'"></v-btn>
							<v-btn
								class="btn_white"
								default
								large
								v-for="(hangerTypeItem1, hangerTypeIdx1) in hangerTypeItems"
								:key="hangerTypeIdx1"
								@click="
									hangerSelect(
										hangerTypeItem1,
										hangerType,
										'hanger1',
										hangerTypeIdx1
									)
								"
							>
								{{ hangerTypeItem1.text }}
							</v-btn>
						</v-btn-toggle>
						<!--// 선택 1 -->

						<!-- 선택 2 -->
						<h4 class="sub_tit_btn" v-if="hangerTypeItems2.length > 0">
							선택2
						</h4>
						<v-btn-toggle
							class="toggle_row"
							v-model="hangerTypeSelected2"
							v-if="hangerTypeItems2.length > 0"
						>
							<v-btn
								class="btn_white"
								default
								large
								v-for="(hangerTypeItem2, hangerTypeIdx2) in hangerTypeItems2"
								:key="hangerTypeIdx2"
								@click="
									hangerSelect(
										hangerTypeItem2,
										hangerType2,
										'hanger2',
										hangerTypeIdx2
									)
								"
							>
								{{ hangerTypeItem2.text }}
							</v-btn>
						</v-btn-toggle>
						<!--// 선택 2 -->
					</v-expansion-panel-content>
				</v-expansion-panel>
				<!--// 추가선택 -->

				<!-- 설치방식 -->
				<v-expansion-panel
					class="accodion_select"
					v-if="(setupTypeItems || freeStandItems) && chkSetupTypePrd()"
				>
					<v-expansion-panel-header>
						<h3 class="sub_tit">설치방식</h3>
						<p class="selected blue-primary">
							{{ setupType && setupType.text.substr(0, 12) }}
							{{ freeStandType && freeStandType.text.substr(0, 12) }}
						</p>
					</v-expansion-panel-header>

					<v-expansion-panel-content>
						<template v-for="(setupTypeItem, setupTypeIdx) in setupTypeItems">
							<v-btn-toggle
								class="toggle_row"
								mandatory
								v-model="setupTypeSelected"
								v-if="setupTypeItem.code === 'B'"
								:key="setupTypeIdx"
							>
								<v-btn :style="'display:none;'"></v-btn>
								<v-btn
									class="btn_extend"
									default
									large
									@click="
										setupTypeEvent(
											setupTypeItem.code,
											setupTypeItem,
											setupTypeIdx
										)
									"
								>
									<div class="flex_lines">
										<div class="product">{{ setupTypeItem.text }}</div>
									</div>
								</v-btn>
							</v-btn-toggle>
							<v-btn-toggle
								class="toggle_row"
								mandatory
								v-model="freeStandCode"
								v-else-if="setupTypeItem.code === 'F'"
								:key="setupTypeIdx"
								:style="`margin-top: 0px !important;`"
							>
								<v-btn :style="'display:none;'"></v-btn>
								<template
									v-for="(freeStandItem, freeStandIdx) in freeStandItems"
								>
									<v-btn
										class="btn_extend"
										default
										large
										v-if="freeStand === undefined"
										:key="freeStandIdx"
										@click="
											setupTypeEvent(
												setupTypeItem.code,
												freeStandItem,
												freeStandIdx
											)
										"
									>
										<div class="flex_lines">
											<div class="product">
												{{ freeStandItem.text }}
											</div>
										</div>
									</v-btn>
									<v-btn
										class="btn_extend"
										default
										large
										v-else
										:key="freeStandIdx"
										@click="setupTypeSelected = undefined"
									>
										{{ freeStandItem.text }} -
										<b>{{ freeStandDisplayText }}</b>
									</v-btn>
								</template>
							</v-btn-toggle>
						</template>
					</v-expansion-panel-content>
				</v-expansion-panel>
				<!--// 설치방식 -->

				<!-- 별매상품 -->
				<v-expansion-panel
					class="accodion_select"
					v-if="prdOption2Items && prdOption2Items.length > 0"
				>
					<v-expansion-panel-header>
						<h3 class="sub_tit">별매상품</h3>
						<p class="selected blue-primary">
							{{ prdOption2 && prdOption2.text.substr(0, 8) }}
							<span class=" text_orange ml-1">{{
								prdOption2 && displayPrdOption2Item(prdOption2).price
							}}</span>
						</p>
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<v-btn-toggle
							class="toggle_row"
							mandatory
							v-model="prdOption2Selected"
						>
							<v-btn
								class="btn_white"
								default
								large
								v-for="(prdOption2Item, prdOption2Idx) in prdOption2Items"
								:key="prdOption2Idx"
								:class="prdOption2Item.code === '' ? '' : 'extend'"
								@click="prdOption2Event(prdOption2Item, prdOption2Idx)"
							>
								<div class="flex_lines">
									<div class="product">
										<em>{{
											displayPrdOption2Item(prdOption2Item).contract
										}}</em>
										{{ displayPrdOption2Item(prdOption2Item).name }}
									</div>
									<div class="price">
										{{ displayPrdOption2Item(prdOption2Item).price }}
									</div>
								</div>
							</v-btn>
						</v-btn-toggle>
					</v-expansion-panel-content>
				</v-expansion-panel>
				<!--// 별매상품 -->
				<!-- 부가서비스 -->
				<v-expansion-panel
					class="accodion_select"
					v-if="
						prdOption1Items &&
							prdOption1Items.length > 0 &&
							this.prdOption1Selected >= 0 &&
							this.agreeTermSelected !== 0
					"
				>
					<v-expansion-panel-header>
						<h3 class="sub_tit">부가서비스</h3>
						<p class="selected blue-primary">
							{{ prdOption1 && prdOption1.text }}
							<span class=" text_orange ml-1">{{
								prdOption1 && displayPrdOption1Item(prdOption1).price
							}}</span>
						</p>
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<div class="panel_txt">
							기존 서비스 주기 또는 교환주기를 ½로 단축하여 제품을 점검해
							드립니다.
						</div>
						<v-btn-toggle
							class="toggle_row"
							mandatory
							v-model="prdOption1Selected"
						>
							<v-btn
								class="btn_white"
								v-for="(prdOption1Item, prdOption1Idx) in prdOption1Items"
								default
								large
								:key="prdOption1Idx"
								@click="prdOption1Event(prdOption1Item, prdOption1Idx)"
							>
								<div class="flex_row">
									<div>{{ displayPrdOption1Item(prdOption1Item).name }}</div>
									<div class=" text_orange">
										{{ displayPrdOption1Item(prdOption1Item).price }}
									</div>
								</div>
							</v-btn>
						</v-btn-toggle>
					</v-expansion-panel-content>
				</v-expansion-panel>
				<!--// 부가서비스 -->
			</v-expansion-panels>
		</div>
		<!--//contents-->

		<!-- 하단 버튼 -->
		<div class="cw-app-fixed">
			<div class="u-btns-group">
				<div class="column">
					<v-btn class="u-btn-text" depressed large @click="reset()">
						재검색
					</v-btn>
				</div>
				<div class="column">
					<v-btn
						class="u-btn-active"
						depressed
						large
						@click="addPrd()"
						:disabled="isDisableBtn"
					>
						상품 담기
					</v-btn>
				</div>
			</div>
		</div>
		<!--// 하단 버튼 -->

		<!-- 상품 미리보기 -->
		<PrdPreview
			ref="prdPreview"
			:bottomPannel="bottomPannel"
			@bottomPannel="bottomPannelClick"
		/>
		<!--// 상품 미리보기 -->

		<!-- 재랜탈 관련 팝업 -->
		<ReturnPenaltyDialog ref="rpd" />
		<!--// 팝업 컴포넌트 -->
	</div>
</template>

<script>
import {
	postWebOrderInfoById,
	fetchWebOrderInfoById
} from '@/apis/order.status.api'
import PrdPreview from '@/views/prd/PrdPreview'
import filters from '@/mixins/filters'
import computeds from '@/mixins/computeds'
import { mapActions } from 'vuex'
import {
	fetchPrdOpts01,
	fetchPrdOpts02,
	fetchPrdOpts03,
	postValidTransfer,
	postTransfer,
	fetchHomePreOrderList
} from '@/apis/order.prd.api'
import { mapGetters } from 'vuex'
import { registerCallbackToApp } from '@/utils/navigation'
import { hasPrds, isChair } from '@/utils/ec'
import { generateRandomHash } from '@/utils/hash'
import moment from 'moment'
import { pad } from '@/utils'
import { getContractId, getChairMultiFacData } from '@/utils/common'
import { getPreContractPayMehdCechk } from '@/apis/pre.contract.api'

export default {
	name: 'PrdDetail',
	mixins: [filters, computeds],
	components: {
		PrdPreview
	},
	created() {
		this.initAppPage()

		if (this.$store.state['verify'].customer.orderType !== '') {
			this.orderType = this.$store.state['verify'].customer.orderType
		} else {
			this.orderType = 'N'
		}
	},
	data() {
		return {
			prdDefaultPrice: 0, // 일시불 금액(가장 낮은)
			prdQuantity: 1, // 상품 수량
			dtl01: undefined, // /v1/02/02/01 상세
			dtl02: undefined, // /v1/02/02/02 상세
			dtl03: undefined, // /v1/02/02/03 상세
			openedPanel: 0,
			prd: {},
			initPrdPrice: 0, // 일시불 초기 값, 초기화 시 사용 금액

			// 판매구분
			sellTypeItems: [],
			sellType: '', // 판매구분
			sellTypeSelected: undefined,

			// 관리유형
			manageType: '', // 관리유형
			mngTypeItems: undefined,
			mngTypeSelected: undefined, // 관리유형

			// 의무사용기간
			agreeTermItems: undefined,
			agreeTermSelected: undefined, // 의무사용기간(약정기간)
			agreeTermType: '', // 의무사용기간

			// 설치방식
			setupTypeItems: [],
			setupTypeSelected: undefined, // 전기레인지 설치방식 - 빌트인/프리스탠드
			setupType: '', // 설치방식

			// 프리스탠드
			freeStandItems: [],
			freeStandType: '', //프리스탠드
			freeStand: undefined, // 설치방식 - 스탠드 추가구매
			freeStandCode: undefined, // 설치방식 - 스탠드 추가구매
			freeStandDisplayText: '',

			// 추가선택
			prodAddSelectCnt: 0, // 추가선택 선택 갯수
			hangerTypeItems: [],
			hangerTypeSelected: undefined, // 공기청정기 추가구매 - 행거
			hangerType: '', // 추가선택1
			hangerTypeItems2: [],
			hangerTypeSelected2: undefined, // 연수기 추가선택2
			hangerType2: '', // 추가선택1

			// 공기청정기 필터
			filterCnt: 0,
			filterSelectedCnt: 0, // 필터 선택 갯수
			filterTypeSelected: undefined, // 공기청정기 추가구매 - 필터
			filterTypeItems: [],
			filterType: '', // 필터타입1
			filterTypeSelected2: undefined, // 공기청정기 추가구매 - 필터2
			filterTypeItems2: [],
			filterType2: '', // 필터타입2
			filterTypeSelected3: undefined, // 공기청정기 추가구매 - 필터3
			filterTypeItems3: [],
			filterType3: '', // 필터타입3
			filterTypeSelected4: undefined, // 공기청정기 추가구매 - 필터4
			filterTypeItems4: [],
			filterType4: '', // 필터타입4

			// 요금제 선택
			regiIdItems: [],
			regiIdSelected: undefined, // 재렌탈요금제

			// 부가서비스
			prdOption1Selected: undefined, // 부가서비스
			prdOption1Items: [],
			prdOption1: '',
			// 별매상품
			prdOption2Selected: undefined, // 별매상품
			prdOption2Items: [],
			prdOption2: '',
			// 멤버십
			membershipType: '', // 멤버십
			membershipSelected: undefined, // 멤버십
			membershipItems: [], // 멤버십
			membership: undefined, // 멤버십
			msMonthAmt: 0, // 멤버십 매월

			// 재랜탈
			rerentalTypeItems: [],
			rerentalTypeSelected: undefined, // 재렌탈구분
			rerentalType: '', // 재랜탈
			returnPromotionApplied: false, //미반납 재렌탈 프로모션 적용되었는지 여부
			returnPenaltyApplied: false, //반납접수 신청했는지 여부
			returnPenaltyAmt: 0, //반납위약금

			// 기존주문번호
			preOrderItems: [],
			preOrderSelected: undefined, // 기존주문번호 선택
			preOrder: '', // 기존주문번호
			prodAddCnt: 0,
			waitClass: 'selected',
			activeClass: '',
			showSellType: true, // 판매구분 출력 여부

			esProdPrice: { regPrice: 0, rentalPrice: 0 },
			tempUseList: [], //관리유형에 따른 의무사용기간을 가져오기 위한 임시 배열
			tempRegi: [],
			firstChk: true,
			bottomPannel: false,
			orderType: '', // 주문구분값 [S: 사전청약, N: 일반주문, C: 사전계약]
			isPreContract: this.$store.state['verify'].customer.orderType === 'C',

			// 홈체험2 주문번호
			homePreOrderItems: [],
			homePreOrderSelected: undefined, // 홈체험2 주문번호 선택
			homePreOrder: '', // 홈체험2 주문번호

			// 다중이용시설 설치장소
			chairMultiFac: '', // 다중이용시설 설치장소 선택된 코드
			chairMultiFacItems: [],
			chairMultiFacSelected: undefined
		}
	},

	async mounted() {
		this.prd = this.$route.params.prd
		this.initPrdPrice = this.prd?.price || 0
		this.$log('params', this.$route.params)
		this.optionsReset()
		await this.getPrdOpts01()
	},

	watch: {
		// 재랜탈 타입 선택
		rerentalType(val) {
			if (val) {
				switch (this.sellType.code) {
					case 'Z002':
						if (!this.chkMngTypePrd()) {
							this.openedPanel += 1
							this.$log('OPEN PANEL =====>>> [1]')
						}
						break
					default:
						this.$log('OPEN PANEL =====>>> [2]')
						//this.openedPanel = 0
						break
				}
			}
		}
	},

	computed: {
		...mapGetters({ verifyCustNo: 'verify/custNo' }),

		isDisableBtn() {
			this.$log('버튼활성화')
			let count = 0
			// 재렌탈
			if (this.rerentalTypeItems && this.rerentalTypeItems.length > 0) {
				if (!this.rerentalType) {
					this.$log('재랜탈 카운트')
					++count
				}
			}
			// 기존주문번호
			if (this.preOrderItems && this.preOrderItems.length > 0) {
				if (!this.preOrder) {
					this.$log('기존주문번호 카운트')
					++count
				}
			}

			if (
				this.sellType.code === 'Z002' &&
				this.regiIdItems &&
				this.regiIdItems.length > 0
			) {
				if (this.regiIdSelected === undefined) {
					this.$log('재랜탈')
					++count
				}
			}

			// 다중이용시설 설치장소
			if (
				['Z001', 'Z002'].includes(this.sellType.code) &&
				this.chairMultiFacItems &&
				this.chairMultiFacItems.length > 0
			) {
				if (!this.chairMultiFac) {
					this.$log('다중이용시설 설치장소')
					++count
				}
			}

			// 관리유형
			if (this.mngTypeItems && this.mngTypeItems.length > 0) {
				if (!this.manageType) {
					this.$log('관리유형')
					++count
				}
			}

			// 의무사용기간
			if (this.agreeTermItems && this.agreeTermItems.length > 0) {
				if (!this.agreeTermType) {
					this.$log('의무사용기간')
					++count
				}
			}

			// 멤버십
			if (this.membershipItems && this.membershipItems.length > 1) {
				if (!this.membershipType) {
					this.$log('멤버십')
					++count
				}
			}

			// 부가서비스
			if (this.prdOption1Items && this.prdOption1Items.length > 1) {
				if (!this.prdOption1) {
					this.$log('부가서비스')
					++count
				}
			}
			// 별매상품
			if (this.prdOption2Items && this.prdOption2Items.length > 1) {
				if (!this.prdOption2) {
					this.$log('별매상품')
					++count
				}
			}

			if (this.chkSetupTypePrd()) {
				if (!this.setupType && !this.freeStandType) {
					this.$log('')
					++count
				}
			}

			if (this.chkHangerTypePrd()) {
				if (!this.hangerType) {
					this.$log('행거')
					++count
				}
			}

			if (this.chkFilterTypePrd()) {
				if (!this.filterType) {
					this.$log('필터')
					++count
				}
			}

			if (this.sellType.code === '') {
				this.$log('판매구분')
				++count
			}

			//필터선택
			if (this.filterTypeItems.length > 0) {
				if (!this.filterType) {
					this.$log('필터선택1')
					++count
				}
			}
			if (this.filterTypeItems2.length > 0) {
				if (!this.filterType2) {
					this.$log('필터선택2')
					++count
				}
			}
			if (this.filterTypeItems3.length > 0) {
				if (!this.filterType3) {
					this.$log('필터선택3')
					++count
				}
			}
			if (this.filterTypeItems4.length > 0) {
				if (!this.filterType4) {
					this.$log('필터선택4')
					++count
				}
			}

			if (this.dtl03) {
				const { TYPE: type } = this.dtl03.E_RETURN
				if (type === 'F') {
					return true
				}
			}

			if (this.hangerTypeItems2.length > 0) {
				if (this.hangerType && this.hangerType2) {
					// eslint-disable-next-line vue/no-side-effects-in-computed-properties
					this.activeClass = ' blue-primary'
				} else {
					// eslint-disable-next-line vue/no-side-effects-in-computed-properties
					this.activeClass = 'selected'
				}
			} else {
				if (this.hangerType) {
					// eslint-disable-next-line vue/no-side-effects-in-computed-properties
					this.activeClass = ' blue-primary'
				} else {
					// eslint-disable-next-line vue/no-side-effects-in-computed-properties
					this.activeClass = 'selected'
				}
			}
			this.$log('count', count)

			if (count > 0 || !this.dtl02) {
				return true
			} else {
				return this.chkIncSelected()
			}
		},

		// 등록비, 렌탈료
		optionAppliedPrice() {
			return {
				regPrice: this.esProdPrice.regPrice || this.prd.reg_price,
				rentalPrice: this.esProdPrice.rentalPrice || this.prd.month_amt
			}
		},

		// 선택된 판매구분 code 리턴 (ex: Z002)
		sellTypeCode() {
			return this.sellType.code
		}
	},

	methods: {
		...mapActions({ showSnackbar: 'common/showSnackbar' }),
		hasPrds,
		getContractId,
		getChairMultiFacData,

		// 판매구분 선택시 초기화
		async optionsReset() {
			this.sellType = undefined
			this.sellTypeSelected = undefined
			this.manageType = undefined
			this.mngTypeSelected = undefined
			this.rerentalType = undefined
			this.rerentalTypeSelected = undefined
			this.agreeTermType = undefined
			this.agreeTermSelected = undefined
			this.membershipType = undefined
			this.membershipSelected = undefined

			this.sellTypeSelectedReset()
			this.resetDisplayRentalAmt()
		},

		// 판매구분 이벤트
		async sellTypeEvent(item, index) {
			if (item.ORD_TYPE !== '') {
				//재렌탈[Z002],일시불보상판매[Z005] 일 경우만 수량 초기화
				if (item.code === 'Z002' || item.code === 'Z005') {
					this.prdQuantity = 1
				}

				this.sellTypeSelectedReset()
				this.prdDefaultPrice = this.prd.price
				this.sellType = item
				this.sellTypeSelected = index + 1
				await this.getPrdOpts02()
				this.$log('OPEN PANEL =====>>> [3]')
				this.openedPanel += 1
			}
		},

		// 요금재(재랜탈)
		async rerentalTypeEvent(item, index) {
			this.$log('TTTTTT', item, index)
			if (item) {
				if (this.sellType.code === 'Z002') {
					let regId = ''
					regId = this.rerentalType ? this.rerentalType.REGI_ID : ''
					this.agreeTermItems = this.tempUseList
						.filter(d => d.USE_TP === this.manageType?.USE_TP)
						.filter(d => d.REGI_ID === regId)
						.map(d => {
							return {
								code: d.USE_DUTY_YEAR,
								text: d.USE_DUTY_YEAR + '년',
								...d
							}
						})
				}
			} else {
				this.agreeTermItems = undefined
				this.agreeTermSelected = undefined
				this.agreeTermType = undefined
			}

			this.regiIdSelected = index
			this.rerentalType = item
			this.openedPanel += 1
			this.$log('OPEN PANEL =====>>> [4]')
		},

		// 관리유형
		async manageTypeEvent(item, index) {
			this.manageType = this.mngTypeItems[index]
			this.mngTypeSelected = index + 1

			if (item) {
				if (this.sellType.code === 'Z002') {
					// 재랜탈
					let regId = ''
					regId = this.rerentalType ? this.rerentalType.REGI_ID : ''
					this.agreeTermItems = this.tempUseList
						.filter(d => d.USE_TP === item.USE_TP)
						.filter(d => d.REGI_ID === regId)
						.map(d => {
							return {
								code: d.USE_DUTY_YEAR,
								text: d.USE_DUTY_YEAR + '년',
								...d
							}
						})
				} else {
					// 그외
					this.agreeTermItems = this.tempUseList
						.filter(d => d.USE_TP === item.USE_TP)
						.map(d => {
							return {
								code: d.USE_DUTY_YEAR,
								text: d.USE_DUTY_YEAR + '년',
								...d
							}
						})
				}

				if (
					this.rerentalType !== undefined &&
					this.manageType !== undefined &&
					this.agreeTermType !== undefined
				) {
					if (this.agreeTermType && this.rerentalType) {
						this.$log('OPEN PANEL =====>>> [5-1]')
						await this.getPrdOpts03()
						this.openedPanel += 1
					}
				} else if (
					this.manageType !== undefined &&
					this.agreeTermType !== undefined
				) {
					if (this.agreeTermType.length > 0) {
						this.$log('OPEN PANEL =====>>> [5-2]')
						await this.getPrdOpts03()
						if (
							this.filterTypeItems.length > 0 ||
							this.hangerTypeItems.length > 0 ||
							this.hangerTypeItems2.length > 0 ||
							this.agreeTermItems.length > 0 ||
							this.preOrderItems.length > 0
						) {
							this.openedPanel += 1
						} else {
							this.openedPanel = undefined
						}
					} else {
						this.agreeTermType = undefined
						this.agreeTermSelected = undefined
						this.prdOption1 = undefined
						this.openedPanel += 1
					}
				} else {
					this.$log('OPEN PANEL =====>>> [5-3]')
					await this.getPrdOpts03()
					this.$log('this.agreeTermItem1', this.agreeTermItems)
					if (this.agreeTermItems.length > 0) {
						this.openedPanel += 1
					} else if (this.preOrderItems.length > 0) {
						this.openedPanel += 1
					} else if (
						this.filterTypeItems.length > 0 ||
						this.filterTypeItems2.length > 0 ||
						this.filterTypeItems3.length > 0 ||
						this.filterTypeItems4.length > 0
					) {
						this.openedPanel += this.membershipItems.length > 0 ? 2 : 1
					} else {
						this.openedPanel = undefined
					}
				}
				// 의무사용기간이 1개 일 경우 자동 선택
				if (this.agreeTermItems && this.agreeTermItems.length === 1) {
					this.$log('this.agreeTermItem2', this.agreeTermItems)
					this.agreeTermTypeEvent(this.agreeTermItems[0], 0)
				}
			}
		},

		// 멤버십 선택
		async membershipTypeEvent(item, index) {
			const option = this.membershipItems
			const result = option ? option[index] : undefined
			const beforeMembershipType = this.membershipType
			const beforeMembershipSelected = this.membershipSelected

			if (result) {
				if (result.code !== '' && result.A127_CHK === 'X') {
					const ok = await this.$alert({
						message:
							'멤버십 적용시 1년무상 하트서비스 프로모션 혜택을 받을 수 없습니다. 그래도 멤버십을 적용하시겠습니까?',
						okText: '확인',
						cancelText: '취소'
					})

					if (ok) {
						this.membershipType = item
						this.membershipSelected = index
						this.openedPanel += 1
						this.$log('OPEN PANEL =====>>> [6]')
					} else {
						this.membershipSelected = beforeMembershipSelected
						this.membershipType = beforeMembershipType
					}
				} else {
					this.membershipType = item
					this.membershipSelected = index
					this.openedPanel += 1
					this.$log('OPEN PANEL =====>>> [7]')
				}
			}
		},

		// 의무사용기간
		async agreeTermTypeEvent(item, index) {
			if (item) {
				if (this.sellTypeCode === 'Z002' && this.regiIdSelected === null) {
					this.agreeTermSelected = null
				} else if (
					this.chairMultiFac &&
					this.chairMultiFac.code !== '' &&
					item.USE_DUTY_YEAR !== '5'
				) {
					const ok = await this.$alert({
						message:
							'설치장소가 다중이용시설인 경우 의무사용기간 5년만 선택 가능합니다.',
						okText: '확인'
					})
					if (ok) {
						this.agreeTermType = ''
						this.agreeTermSelected = null
						return
					}
				} else {
					this.agreeTermType = item
					this.agreeTermSelected = index + 1
				}

				if (
					this.regiIdItems.length > 0 &&
					this.mngTypeItems.length > 0 &&
					this.agreeTermItems.length > 0
				) {
					if (
						this.agreeTermType !== undefined &&
						this.rerentalType !== undefined
					) {
						await this.getPrdOpts03()
					}
				} else if (
					this.mngTypeItems.length > 0 &&
					this.agreeTermItems.length > 0
				) {
					if (this.manageType) {
						await this.getPrdOpts03()
					} else {
						if (this.mngTypeItems.length > 1) {
							//this.openedPanel = 1 // 관리유형 2건일 때 관리유형패널 오픈
							this.$log('OPEN PANEL =====>>> [8]')
						}
					}
				} else {
					await this.getPrdOpts03()
				}

				if (
					this.filterTypeItems.length > 0 ||
					this.filterTypeItems2.length > 0 ||
					this.freeStandItems.length > 0 ||
					this.setupTypeItems.length > 0 ||
					this.hangerTypeItems.length > 0 ||
					this.hangerTypeItems2.length > 0 ||
					this.preOrderItems.length > 0
				) {
					this.$log('OPEN PANEL =====>>> [9-1]')
					this.openedPanel += 1
				} else {
					this.$log('OPEN PANEL =====>>> [9-2]')
					this.openedPanel = undefined
				}

				this.$log('OPEN PANEL =====>>> [9]')
			}
		},

		// 설치방식 (설치방식, 프리스탠드)
		setupTypeEvent(code, item, index) {
			if (code === 'B') {
				// 빌트인
				this.setupType = item
				this.setupTypeSelected = index + 1
				this.freeStandCode = undefined
				this.freeStandType = undefined
			} else if (code === 'F') {
				// 프리스탠드
				this.freeStandType = item
				this.freeStandCode = index + 1
				this.setupType = undefined
				this.setupTypeSelected = undefined
			}

			this.openedPanel = undefined
			this.$log('OPEN PANEL =====>>> [10]')
		},

		/**
		 * 별매상품
		 * @param item 선택된 별매상품 정보
		 * @param index 선택된 별매상품 INDEX
		 */
		prdOption1Event(item, index) {
			if (item) {
				this.prdOption1 = item
				this.prdOption1Selected = index
				this.openedPanel += 1
				this.$log('OPEN PANEL =====>>> [11]')
			}
		},

		/**
		 * 부가서비스
		 * @param item 선택된 부가서비스 정보
		 * @param index 선택된 부가서비스 INDEX
		 */
		prdOption2Event(item, index) {
			if (item) {
				this.prdOption2 = item
				this.prdOption2Selected = index
				this.openedPanel = undefined
				this.$log('OPEN PANEL =====>>> [12]')
			}
		},

		/**
		 * 기존주문번호 선택
		 * @param item 선택된 기존주문
		 * @param index 선택된 기존주문 INDEX
		 * @returns {Promise<void>}
		 */
		async perOrderEvent(item, index) {
			const beforePreOrder = this.preOrder
			const beforePreOrderSelected = this.preOrderSelected
			// 사전계약 동일 도래월 체크
			if (this.isPreContract) {
				let message = ''
				const prevList = this.$store.getters['prd/prdList']
				if (
					prevList &&
					prevList.length > 0 &&
					prevList[0].options?.preOrder?.PRE_COUNT !== item.PRE_COUNT
				) {
					message = `앞서 담은 상품에 사용된 기존계정의 소유권도래월(M-${prevList[0].options.preOrder.PRE_COUNT})과 달라서 선택이 불가합니다.`
					this.$alert({ message })
					return
				}

				const params = {
					IN_TAB: [{ ORDER_NO: item.ORDER_NO }]
				}
				let payMehdCheck = false
				await getPreContractPayMehdCechk(params)
					.then(res => {
						if (res.resultObject.E_RETURN.TYPE !== 'S') {
							message =
								'선택한 기존계정(주문)의 결제수단이 변경 중으로 선택하실 수 없습니다. 결제수단 변경이 완료된 후 다시 진행해주세요.'
							payMehdCheck = true
						}
					})
					.catch(() => {
						payMehdCheck = true
						message =
							'일시적인 통신 장애로 서비스에 접속할 수 없습니다. 문제가 계속된다면, 담당자에게 문의해 주세요.'
					})

				if (payMehdCheck) {
					this.$alert({
						message: message
					})
					return
				}
			}
			this.preOrder = item
			this.preOrderSelected = index + 1
			if (item) {
				//일시불 보상판매인경우
				if (this.sellTypeCode === 'Z005' && item.RTN_YN !== 'A') {
					this.returnPromotionPopup(item, index)
				} else {
					// 프로모션 선택사항 (RTN: X)
					if (item.RTN_YN === 'X') {
						const res = await this.$alert({
							message:
								'미반납 재렌탈 프로모션 적용이 가능합니다. <br/>적용하시겠습니까?',
							cancelText: '미적용',
							okText: '적용'
						})

						if (res) {
							this.returnPromotionApplied = true
							this.openedPanel = undefined
							this.$log('OPEN PANEL =====>>> [13]')
						} else {
							// 반납접수 화면 호출
							this.returnPromotionPopup(item, index)
						}
					} else if (item.RTN_YN === 'Y') {
						// 반납접수 화면 호출
						this.returnPromotionPopup(item, index)
					} else if (item.RTN_YN === 'N') {
						const res = await this.$alert({
							message:
								'미반납 재렌탈 프로모션 적용이 필수 입니다. <br/>적용하시겠습니까?',
							cancelText: '미적용',
							okText: '적용'
						})

						if (res) {
							this.returnPromotionApplied = true
							this.openedPanel = undefined
							this.$log('OPEN PANEL =====>>> [14]')
						} else {
							this.$log('this.preOrder', this.preOrder)
							this.$log('beforePreOrder', beforePreOrder)
							// 미적용시 이전 선택값 적용

							if (
								beforePreOrder === undefined ||
								this.preOrder.ORDER_NO === beforePreOrder.ORDER_NO
							) {
								this.preOrder = ''
								this.preOrderSelected = undefined
							} else {
								this.preOrder = beforePreOrder
								this.preOrderSelected = beforePreOrderSelected
							}
						}
					} else if (item.RTN_YN === 'M') {
						await this.$alert({
							message: '이 제품은 반납하지 않습니다.',
							okText: '확인'
						})
						// 반환미접수
						this.returnPenaltyApplied = false
						this.openedPanel = undefined
						this.$log('OPEN PANEL =====>>> [15]')
					} else if (item.RTN_YN === 'A') {
						const res = await this.$alert({
							message: '기존 제품을 반납하시겠습니까?',
							cancelText: '아니오',
							okText: '예'
						})
						if (res) {
							// 반납접수 화면 호출
							this.returnPromotionPopup(item, index)
						} else {
							this.returnPenaltyApplied = false
							this.openedPanel = undefined
						}
					}
				}
			} else {
				return
			}
		},

		bottomPannelClick(param) {
			this.bottomPannel = param
		},

		// 수량번경 불가 (재랜탈, 일시불 보상, 파운데이션 렌탈)
		isPrdQuantity(p) {
			const rentalSellTypes = ['Z002', 'Z005']
			const sellType = p.code

			if (rentalSellTypes.includes(sellType)) {
				return rentalSellTypes.includes(sellType)
			} else {
				if (p.code === 'Z001' && this.prd.display_cate2 === 404) {
					// 파운데이션의 경우 비활성화
					return true
				} else {
					return rentalSellTypes.includes(sellType)
				}
			}
		},

		// 랜탈여부
		isRental(p) {
			const rentalSellTypes = ['Z001', 'Z002']
			const sellType = p.code
			return rentalSellTypes.includes(sellType)
		},

		// 옵션초기화
		optionReset() {
			this.toggleSellType(true)
			this.openedPanel = 0
			this.$log('OPEN PANEL =====>>> [16]')

			this.showSnackbar({
				msg: '선택한 옵션이 초기화 되었습니다.',
				color: '#222'
			})
		},

		// STEP01
		async getPrdOpts01() {
			if (this.orderType !== 'S') {
				// 일반주문은 상품 선택에서 조회
				let presubCd = this.orderType === 'S' ? 'X' : ''
				presubCd = this.orderType === 'C' ? 'C' : presubCd
				let res = await fetchPrdOpts01({
					matnr: this.prd.erp_cd,
					kunnr: this.$store.state['verify'].customer.custNo, // 고객번호
					salesCd: this.$store.getters['common/codyNo'], // 코디번호 코디사번
					presub: presubCd // 사전청약(S): X, 계약이면(C): C, 그외 빈값
				})
				if (res.resultObject.item.E_RETURN.TYPE === 'F') {
					this.showSellType = false
				} else {
					const data = res.resultObject.item.IT_ZSDS1034
					if (data.findIndex(x => x.PRDHA === '005060') !== -1) {
						await this.$alert({
							message:
								'도시가스 차단은 고객님께서<br/>도시가스 업체에 연락하여<br/>' +
								'마감해야 합니다.<br/>(서비스 매니저 마감 불가)<br/><br/>' +
								'도시가스 마감이 되지 않은 상태로는<br/>설치가 불가 합니다.<br/>' +
								'고객님께 필히 안내 부탁 드립니다.',
							okText: '확인'
						})
					}
				}

				await this.setHomePreOrderList()
				await this.setOptions(res, '01')
			} else {
				// 사전예약의 경우 상품 검색에서 조회한 데이터 활용
				const res = this.$route.params.prdOpts01
				if (res.resultObject.item.E_RETURN.TYPE === 'F') {
					this.showSellType = false
				} else {
					const data = res.resultObject.item.IT_ZSDS1034
					if (data.findIndex(x => x.PRDHA === '005060') !== -1) {
						await this.$alert({
							message:
								'도시가스 차단은 고객님께서<br/>도시가스 업체에 연락하여<br/>' +
								'마감해야 합니다.<br/>(서비스 매니저 마감 불가)<br/><br/>' +
								'도시가스 마감이 되지 않은 상태로는<br/>설치가 불가 합니다.<br/>' +
								'고객님께 필히 안내 부탁 드립니다.',
							okText: '확인'
						})
					}
				}
				await this.setHomePreOrderList()
				await this.setOptions(res, '01')
			}
		},

		// STEP02
		async getPrdOpts02() {
			let res = await fetchPrdOpts02({
				matnr: this.prd.erp_cd,
				kunnr: this.$store.state['verify'].customer.custNo, // 고객번호
				ordType: this.sellType.code,
				prdItem: this.dtl01.IT_ZSDS1034, // 상품찾기
				contractId: this.getContractId(this.sellType.code)
			})

			await this.setOptions(res, '02')
		},

		// STEP03
		async getPrdOpts03() {
			if (this.sellType.code !== '') {
				let params = {
					matnr: this.prd.erp_cd,
					kunnr: this.$store.state['verify'].customer.custNo, // 고객번호
					ordType: this.sellType.code,
					prdItem: this.dtl02.IT_ZSDS1034,
					contractId: this.getContractId(this.sellType.code),
					newGooods: this.prd.erp_cd,
					preSaleCd: this.isPreContract
						? this.$store.getters['common/codyNo']
						: '' // 사전계약이면 코디사번, 그외 빈값
				}
				let initUseTp = ''
				if (this.agreeTermItems && this.agreeTermItems.length === 1) {
					initUseTp = this.agreeTermItems[0].USE_TP
				}

				if (this.agreeTermType !== '' && this.agreeTermType !== undefined) {
					params = Object.assign(params, {
						useDutyYear: this.agreeTermType.code,
						useTp: initUseTp,
						prdItem: this.dtl02.IT_ZSDS1034
					})

					if (!params.contractId) {
						params = Object.assign(params, {
							contractId: this.agreeTermType.CONTRACT_ID
						})
					}
				} else {
					params = Object.assign(params, {
						useTp: initUseTp,
						prdItem: this.dtl02.IT_ZSDS1034
					})
				}

				// 재랜탈의 경우
				if (this.regiIdSelected !== undefined) {
					params = { ...params, regiId: this.rerentalType.REGI_ID }
				}

				// 관리유형의 경우
				if (this.manageType) {
					params = Object.assign(params, {
						useTp: initUseTp === '' ? this.manageType.code : initUseTp,
						onceGrade1: this.manageType.ONCE_GRADE1,
						onceGrade2: this.manageType.ONCE_GRADE2
					})
					if (!params.contractId) {
						params = Object.assign(params, {
							contractId: this.manageType.CONTRACT_ID
						})
					}
				}

				if (this.chairMultiFac && this.chairMultiFac !== '') {
					if (this.agreeTermType === undefined || this.agreeTermType === '')
						return

					params.prdItem.forEach(v => {
						v.PLACE_ID2 = this.chairMultiFac.code
						v.RENTAL_YEARS = this.agreeTermType.OWNER
					})
				}

				const res = await fetchPrdOpts03(params)
				this.dtl03 = res.resultObject.item
				this.dtl03.option = res.resultObject.option

				await this.setOptions(res, '03')

				// 별매상품
				if (this.dtl03.option && this.dtl03.option.length > 0) {
					this.prdOption2Items = []
					this.prdOption2Items.push({ code: '', text: '추가 안함' })
					const prdOptionList = this.dtl03.option
						.filter(
							v => v.CONTRACT_ID === this.getContractId(this.sellType.code)
						)
						.map(d => {
							return {
								code: d.GOODS_CD,
								text: d.GOODS_NM,
								...d
							}
						})

					this.prdOption2Selected = 0
					this.prdOption2 = this.prdOption2Items[0]
					const existingPrdOptions = []

					const prdOptionList2 = this.dtl03.option
						.filter(
							v =>
								v.CONTRACT_ID !== this.getContractId(this.sellType.code) &&
								!existingPrdOptions.includes(v.GOODS_CD)
						)
						.map(d => {
							return {
								code: d.GOODS_CD,
								text: d.GOODS_NM,
								...d
							}
						})
					this.prdOption2Items = [
						...this.prdOption2Items,
						...prdOptionList,
						...prdOptionList2
					]
					this.prdOption2Selected = 0 //추가 안함이 디폴트 선택
				}

				// 부가서비스
				if (
					this.dtl03.ET_ZSDS1802 &&
					this.dtl03.ET_ZSDS1802.length > 0 &&
					this.isRental(this.sellType)
				) {
					this.prdOption1Items = []
					this.prdOption1Items.push({ code: '', text: '추가 안함' })
					this.dtl03.ET_ZSDS1802.forEach(v => {
						this.prdOption1Items.push({
							code: v.ADSVC,
							text: v.ADSVC_TXT,
							...v
						})
					})
					this.prdOption1Selected = 0 //부가서비스 추가안함 디폴트 선택
					this.prdOption1 = this.prdOption1Items[0]
				} else {
					this.prdOption1Items = []
				}

				//렌탈, 재렌탈일 경우 esProd를 반영한다
				if (['Z001', 'Z002'].includes(this.sellTypeCode)) {
					const {
						REG_PRICE: regPrice,
						MONTH_AMT: rentalPrice
					} = this.dtl03.ES_PROD
					this.esProdPrice = {
						regPrice,
						rentalPrice
					}
				}

				// 일시불, 자체할부, 일시불 보상판매
				if (['Z003', 'Z004', 'Z005'].includes(this.sellTypeCode)) {
					this.prd.price = this.dtl03.ES_PROD.PRICE
				}

				//조건에 해당하는 정보 조회시 실패체크
				if (this.dtl03) {
					const { TYPE: type, MESSAGE: message } = this.dtl03.E_RETURN
					if (type === 'F') {
						this.$alert({ message })
					}
				}
			}
		},

		// Options에 Data 추가
		async setOptions(res, step) {
			let dtl = {}
			if (step === '01') {
				this.dtl01 = res.resultObject.item
				dtl = this.dtl01
			} else if (step === '02') {
				this.dtl02 = res.resultObject.item
				dtl = this.dtl02
			} else if (step === '03') {
				this.dtl03 = res.resultObject.item
				dtl = this.dtl03
			}

			if (step === '01') {
				// 판매구분
				if (dtl.IT_ORD_TYPE && dtl.IT_ORD_TYPE.length > 0) {
					this.sellTypeItems = dtl.IT_ORD_TYPE.map(d => {
						return {
							code: d.ORD_TYPE,
							text: d.TEXT,
							...d
						}
					})
				}
				const activeItems = []
				const activeItemIndexs = []
				this.sellTypeItems.forEach((v, i) => {
					if (v.ORD_ACTIVE === '1') {
						activeItems.push(v)
						activeItemIndexs.push(i)
					}
				})

				if (activeItems.length === 1) {
					this.$log('activeItems', activeItems[0])
					this.$log('activeItemIndexs', Number(activeItemIndexs[0]))
					this.sellTypeEvent(activeItems[0], Number(activeItemIndexs[0]))
				}
			}

			if (step === '02') {
				// 재렌탈 요금제(regiId)
				if (dtl.IT_REGI_ID && dtl.IT_REGI_ID.length > 0) {
					this.regiIdItems = dtl.IT_REGI_ID.map(d => {
						return {
							code: d.REGI_ID,
							text: d.REGI_ID_NM,
							...d
						}
					})
				}

				// 관리유형
				if (dtl.IT_USE_TP_LIST && dtl.IT_USE_TP_LIST.length > 0) {
					if (this.sellTypeCode === 'Z001' || this.sellTypeCode === 'Z002') {
						// 랜탈, 재랜탈
						this.mngTypeItems = dtl.IT_USE_TP_LIST.map(d => {
							return {
								code: d.USE_TP,
								text: d.USE_TP_NM,
								ONCE_GRADE1: '',
								ONCE_GRADE2: '',
								...d
							}
						})
					} else {
						// 그외
						this.mngTypeItems = dtl.IT_USE_TP_LIST.map(d => {
							return {
								code: d.USE_TP,
								text: d.USE_TP_NM,
								ONCE_GRADE1: '',
								ONCE_GRADE2: '',
								...d
							}
						})

						dtl.IT_ZSDS1034.filter(d => {
							this.mngTypeItems.map(v => {
								if (
									v.CONTRACT_ID === d.CONTRACT_ID &&
									v.ONCE_GRADE1 === d.ONCE_GRADE1 &&
									v.ONCE_GRADE2 === d.ONCE_GRADE2
								) {
									v.price = d.PRICE
								}
							})
						})
					}

					if (this.mngTypeItems && this.mngTypeItems.length === 1) {
						this.mngTypeSelected = 0
					} else if (
						this.sellType.code !== 'Z002' &&
						!this.regiIdItems &&
						!this.regiIdItems.length > 0
					) {
						this.$log('TTTTTTTT')
						//this.openedPanel += 1
						this.$log('OPEN PANEL =====>>> [17]')
					}
				} else {
					// 없을 경우 초기화
					this.mngTypeItems = []
				}

				// 요금제가 1개이면 적용
				if (this.regiIdItems.length === 1) {
					await this.rerentalTypeEvent(this.regiIdItems[0], 0)
				}

				// 의무사용기간(약정기간)
				if (dtl.IT_USE_DUTY_YEAR && dtl.IT_USE_DUTY_YEAR.length > 0) {
					this.tempUseList = dtl.IT_ZSDS1034
					this.tempRegi = dtl.IT_REGI_ID
					if (this.sellType.code === 'Z002') {
						this.$log('의무약정기간1')
						this.agreeTermItems = this.tempUseList
							.filter(d => d.REGI_ID === dtl.IT_REGI_ID[0].REGI_ID)
							.filter(d => d.USE_TP === dtl.IT_USE_TP_LIST[0].USE_TP)
							.map(d => {
								return {
									code: d.USE_DUTY_YEAR,
									text: d.USE_DUTY_YEAR + '년',
									...d
								}
							})
					} else {
						this.$log('의무약정기간2')
						this.tempUseList = dtl.IT_ZSDS1034
						this.agreeTermItems = this.tempUseList
							.filter(d => d.USE_TP === dtl.IT_USE_TP_LIST[0].USE_TP)
							.map(d => {
								return {
									code: d.USE_DUTY_YEAR,
									text: d.USE_DUTY_YEAR + '년',
									...d
								}
							})
					}

					if (
						this.manageType !== undefined &&
						this.agreeTermItems &&
						this.agreeTermItems.length === 1
					) {
						this.agreeTermTypeEvent(this.agreeTermItems[0], 0)
						// this.agreeTermType = this.agreeTermItems[0]
						// this.agreeTermSelected = 0
						// this.openedPanel += 1
						this.$log('OPEN PANEL =====>>> [18]')
					}
				}

				// 안마의자 다중이용시설
				if (dtl.IT_ZSDS1034 && dtl.IT_ZSDS1034.length > 0) {
					const tmpItem = dtl.IT_ZSDS1034.filter(v => v.GOODS_ID === '001')
					if (
						isChair(tmpItem[0]?.PRODH) &&
						['Z001', 'Z002'].includes(this.sellTypeCode)
					) {
						this.chairMultiFacItems = this.getChairMultiFacData(
							'InstallType'
						).filter(v => v.isShow)
						// 요금제 하나일 경우 패널 -1 (위에서 rerentalTypeEvent()함수 실행되므로)
						if (this.regiIdItems.length === 1) {
							this.openedPanel -= 1
							this.$log('Minus PANEL =====>>> [1]')
						}
					} else {
						this.chairMultiFacItems = []
					}
				}
			}

			// 기존주문번호 선택
			if (step === '03') {
				let obj = res.resultObject

				if (obj.order && obj.order.length > 0) {
					this.preOrderItems = obj.order
				}

				//멤버십 초기화
				this.membershipItems = []
				this.membershipSelected = undefined
				this.membershipType = undefined

				// 멤버십
				if (dtl.ET_ZSDS5230 && dtl.ET_ZSDS5230.length > 0) {
					this.membershipItems.push({ code: '', text: '추가 안함' })
					dtl.ET_ZSDS5230.forEach((v, i) => {
						this.membershipItems.push({
							code: pad(i + 1),
							text: v.USE_TP_NM,
							...v
						})
					})
					this.membershipSelected = 0
					this.membershipType = { code: '', text: '추가 안함' }
				}

				//필터2개 선택(필터선택1, 필터선택2) 으로 확장
				if (Array.isArray(dtl.ET_PROD_ADD_FILTER)) {
					const filterTypeList1 = dtl.ET_PROD_ADD_FILTER.filter(
						x => x.OPT_GRP === '01'
					).map(d => {
						return {
							code: d.MATNR,
							text: d.MAKTX,
							...d
						}
					})

					const filterTypeList2 = dtl.ET_PROD_ADD_FILTER.filter(
						x => x.OPT_GRP === '02'
					).map(d => {
						return {
							code: d.MATNR,
							text: d.MAKTX,
							...d
						}
					})

					const filterTypeList3 = dtl.ET_PROD_ADD_FILTER.filter(
						x => x.OPT_GRP === '08'
					).map(d => {
						return {
							code: d.MATNR,
							text: d.MAKTX,
							...d
						}
					})

					const filterTypeList4 = dtl.ET_PROD_ADD_FILTER.filter(
						x => x.OPT_GRP === '09'
					).map(d => {
						return {
							code: d.MATNR,
							text: d.MAKTX,
							...d
						}
					})

					this.filterTypeItems = [...filterTypeList1]
					this.filterTypeItems2 = [...filterTypeList2]
					this.filterTypeItems3 = [...filterTypeList3]
					this.filterTypeItems4 = [...filterTypeList4]

					this.filterCnt = +dtl.E_FILTER_CNT

					this.$log('OPEN PANEL =====>>> [19]')
				}
			}

			// 추가구매(행거타입)
			this.prodAddSelectCnt = 0 // 추가선택 선택 갯수
			this.hangerTypeItems = []
			this.hangerTypeSelected = undefined // 공기청정기 추가구매 - 행거
			this.hangerType = '' // 추가선택1
			this.hangerTypeItems2 = []
			this.hangerTypeSelected2 = undefined // 연수기 추가선택2
			this.hangerType2 = '' // 추가선택1

			if (dtl.ET_PROD_ADD && dtl.ET_PROD_ADD.length > 0) {
				const erp_cds = '112914,112984,113073' // 해당 상품는 추가구매 선택 안함 제외
				if (erp_cds.indexOf(this.prd.erp_cd) > -1) {
					this.hangerTypeItems.push({ code: '', text: '선택 안함' })
				}

				let hangerTypeList = dtl.ET_PROD_ADD.filter(
					x => x.OPT_GRP === '01'
				).map(d => {
					return {
						code: d.MATNR,
						text: d.MAKTX,
						...d
					}
				})

				let hangerTypeList2 = dtl.ET_PROD_ADD.filter(
					x => x.OPT_GRP === '02'
				).map(d => {
					return {
						code: d.MATNR,
						text: d.MAKTX,
						...d
					}
				})

				//01그룹이 없고 02 그룹만 있을경우 추가선택1에 02 그룹을 넣어줌
				if (hangerTypeList.length === 0 && hangerTypeList2.length !== 0) {
					hangerTypeList = hangerTypeList2
					hangerTypeList2 = []
				}

				//01, 02 그룹이 모두 없을경우는 그냥 넣음
				if (hangerTypeList.length === 0 && hangerTypeList2.length === 0) {
					hangerTypeList = dtl.ET_PROD_ADD.map(d => {
						return {
							code: d.MATNR,
							text: d.MAKTX,
							...d
						}
					})
				}

				this.prodAddCnt = 0
				this.hangerTypeItems = [...this.hangerTypeItems, ...hangerTypeList]
				this.hangerTypeItems2 = [...this.hangerTypeItems2, ...hangerTypeList2]
				if (this.hangerTypeItems.length > 0) this.prodAddCnt++
				if (this.hangerTypeItems2.length > 0) this.prodAddCnt++
			}

			// 설치방식
			if (dtl.ET_ELEC_RANGE_BLT && dtl.ET_ELEC_RANGE_BLT.length > 0) {
				this.setupTypeItems = dtl.ET_ELEC_RANGE_BLT.map(d => {
					return {
						code: d.ELEC_RANGE_BLT,
						text: d.TEXT,
						...d
					}
				})
			}

			// // 설치방식 - 프리스탠드
			if (dtl.ET_PROD_STAND && dtl.ET_PROD_STAND.length > 0) {
				this.freeStandItems = dtl.ET_PROD_STAND.map(d => {
					return {
						code: d.MATNR,
						text: d.MAKTX,
						...d
					}
				})
			}

			if (
				this.chkSetupTypePrd() ||
				this.chkHangerTypePrd() ||
				this.chkFilterTypePrd()
			) {
				this.showSellType = false
			}

			// 관리유형 & 의무사용기간(약정기간) 둘다 없으면 03 호출
			if (
				step === '02' &&
				dtl.IT_USE_TP_LIST.length === 0 &&
				dtl.IT_USE_DUTY_YEAR.length === 0
			) {
				await this.getPrdOpts03()
			}
		},

		// 설치방식 체크
		chkSetupTypePrd() {
			const res =
				this.dtl03 &&
				this.dtl03.ET_ELEC_RANGE_BLT &&
				this.dtl03.ET_ELEC_RANGE_BLT.length > 0
			return res
		},

		// 추가 체크
		chkIncSelected() {
			if (this.hangerTypeItems2.length > 0) {
				if (this.hangerType && this.hangerType2) {
					//this.activeClass = ' blue-primary'
					return false
				} else {
					return true
				}
			} else {
				return false
			}
		},

		// 행거타입 체크
		chkHangerTypePrd() {
			const res =
				this.dtl03 &&
				this.dtl03.ET_PROD_ADD &&
				this.dtl03.ET_PROD_ADD.length > 0
			return res
		},

		// 필터타입 체크
		chkFilterTypePrd() {
			const res =
				this.dtl03 &&
				this.dtl03.ET_PROD_ADD_FILTER &&
				this.dtl03.ET_PROD_ADD_FILTER.length > 0
			return res
		},

		// 관리유형 체크
		chkMngTypePrd() {
			const res =
				this.dtl02 &&
				this.dtl02.IT_USE_TP_LIST &&
				this.dtl02.IT_USE_TP_LIST.length > 0
			return res
		},

		// 구매 유형에 따른 금액 노출 여부
		showPrice(type) {
			if (type === '2') {
				if (
					this.sellType.code === '' ||
					this.sellType.code === 'Z001' ||
					this.sellType.code === 'Z002'
				) {
					return true
				} else {
					return false
				}
			} else {
				if (
					this.sellType.code === '' ||
					this.sellType.code === 'Z003' ||
					this.sellType.code === 'Z004' ||
					this.sellType.code === 'Z005'
				) {
					return true
				} else {
					return false
				}
			}
		},

		// 판매구분
		toggleSellType(show) {
			if (show) {
				this.resetSellType()
			}
		},

		// 판매구분 선택시 초기화
		async sellTypeSelectedReset() {
			this.firstChk = true
			this.filterSelectedCnt = 0
			this.prodAddSelectCnt = 0
			this.sellType = { code: '', text: '' }
			this.regiIdSelected = undefined
			this.mngTypeSelected = undefined
			this.agreeTermSelected = undefined
			this.rerentalTypeSelected = undefined
			this.preOrderSelected = undefined
			this.prdOption1Selected = undefined
			this.prdOption2Selected = undefined

			this.mngTypeItems = []
			this.agreeTermItems = []
			this.rerentalTypeItems = []
			this.preOrderItems = []
			this.prdOption1Items = []
			this.prdOption2Items = []
			this.chairMultiFacItems = []

			this.manageType = undefined
			this.agreeTermType = undefined
			this.rerentalType = undefined
			this.preOrder = undefined
			this.prdOption1 = undefined
			this.prdOption2 = undefined
			this.chairMultiFac = undefined

			//설치방식 초기화부분
			this.setupType = undefined
			this.freeStandType = undefined
			this.setupTypeSelected = undefined
			this.freeStandCode = undefined
			this.setupTypeItems = []
			this.freeStandItems = []

			//필터초기화
			this.filterTypeItems = []
			this.filterTypeSelected = undefined
			this.filterType = undefined

			this.filterTypeItems2 = []
			this.filterTypeSelected2 = undefined
			this.filterType2 = undefined

			this.filterTypeItems3 = []
			this.filterTypeSelected3 = undefined
			this.filterType3 = undefined

			this.filterTypeItems4 = []
			this.filterTypeSelected4 = undefined
			this.filterType4 = undefined

			//멤버십 초기화
			this.membershipSelected = undefined
			this.membershipItems = []
			this.membershipType = undefined

			//추가선택 초기화
			this.hangerType = undefined
			this.hangerTypeSelected = undefined
			this.hangerTypeItems = []
			this.hangerType2 = undefined
			this.hangerTypeSelected2 = undefined
			this.hangerTypeItems2 = []

			//요금제 초기화
			this.regiIdItems = []
			//this.rerentalType = ''
			this.regiIdSelected = undefined
			this.openedPanel = 0
			//this.$log('OPEN PANEL =====>>> [20]')

			this.returnPromotionApplied = false
			this.returnPenaltyApplied = false
			this.returnPenaltyAmt = 0

			// 일시불 초기화
			this.prd.price = this.initPrdPrice

			this.resetDisplayRentalAmt()
		},

		// 옵션초기화
		resetSellType() {
			this.sellType = { code: '', text: '' }
			this.sellTypeSelected = undefined

			this.sellTypeSelectedReset()
			this.resetDisplayRentalAmt()
		},

		isUsedPreOrder(preOrder) {
			let is = false
			const prevList = this.$store.getters['prd/prdList']
			is = prevList.some(prd => {
				if (prd.options.preOrder) {
					is = prd.options.preOrder.ORDER_NO === preOrder.ORDER_NO
				} else {
					is = false
				}
				return is
			})

			return is
		},

		reset() {
			this.$router.push('/order/prd/search')
		},

		// 반납접수 화면 호출
		async returnPromotionPopup(item, index) {
			this.returnPromotionApplied = false
			const res2 = await this.$refs.rpd.open({
				orderNo: item.ORDER_NO,
				contractId: item.CONTRACT_ID,
				userId: this.$store.getters['common/codyNo'],
				mobOrderNo: this.$store.getters['verify/mobOrderNo']
			})

			if (res2) {
				this.returnPenaltyApplied = true

				if (
					this.filterTypeItems.length > 0 ||
					this.filterTypeItems2.length > 0 ||
					this.hangerTypeItems.length > 0 ||
					this.hangerTypeItems2.length > 0 ||
					this.freeStandItems.length > 0 ||
					this.setupTypeItems.length > 0
				) {
					this.$log('반환접수')
					this.openedPanel += 1
				} else {
					this.openedPanel = undefined
				}

				this.preOrder = item
				this.preOrderSelected = index + 1
				this.$log('OPEN PANEL =====>>> []')
			} else {
				this.preOrderSelected = undefined
				this.preOrder = ''
			}
		},

		async initAppPage() {
			registerCallbackToApp(
				{
					cbPhysicalBack: 'cbPhysicalBack'
				},
				this
			)
		},

		async cbPhysicalBack() {
			const ok = await this.$alert({
				message: '상품 검색 화면으로 이동하시겠습니까?',
				okText: '확인',
				cancelText: '취소'
			})
			// if (ok) closePopView()
			if (ok) this.$router.push('/order/prd/search')
		},

		resetDisplayRentalAmt() {
			//판매구분 재설정시 표시 등록비/렌탈료 재설정
			this.esProdPrice = {
				regPrice: this.prd.reg_price,
				rentalPrice: this.prd.month_amt
			}
		},

		// 부가서비스 리스트
		displayPrdOption1Item(item) {
			if (!item.code) return { name: '추가 안함' }

			const price = item.SVAMT
			const optionItem = {
				price: `+${this.$options.filters.comma(price)}원/월`,
				name: item.text
			}
			return optionItem
		},

		// 별매상품 리스트
		displayPrdOption2Item(item) {
			if (!item.code) return { name: '추가 안함' }
			const contract = item.CONTRACT_ID === '1' ? '일시불' : '렌탈'
			const price =
				item.CONTRACT_ID === '1'
					? item.GOODS_AMT === '0'
						? item.PRICE
						: item.GOODS_AMT
					: item.MONTH_AMT
			const div = item.CONTRACT_ID === '1' ? '+' : '월 +'
			const optionItem = {
				contract: `[${contract}]`,
				price: `${div} ${this.$options.filters.comma(price)}원`,
				name: item.text
			}
			return optionItem
		},

		//필터총갯수와 선택한 필터수가 같으면 패널 닫음
		filterSelect(item, data, ref, index) {
			if (item !== data && !data) {
				this.filterSelectedCnt += 1
			}

			if (ref === 'filter1') {
				this.filterType = item
				this.filterTypeSelected = index + 1
			} else if (ref === 'filter2') {
				this.filterType2 = item
				this.filterTypeSelected2 = index + 1
			} else if (ref === 'filter3') {
				this.filterType3 = item
				this.filterTypeSelected3 = index + 1
			} else if (ref === 'filter4') {
				this.filterType4 = item
				this.filterTypeSelected4 = index + 1
			}

			if (this.filterCnt === this.filterSelectedCnt) {
				this.openedPanel = undefined
				this.$log('OPEN PANEL =====>>> [22]')
			}
		},

		//추가선택 총 갯수와 선택한 추가선택수가 같으면 패널 닫음
		hangerSelect(item, data, ref, index) {
			if (item !== data && !data) {
				this.prodAddSelectCnt += 1
			}

			if (ref === 'hanger1') {
				this.hangerTypeSelected = index + 1
				this.hangerType = item
			} else if (ref === 'hanger2') {
				this.hangerTypeSelected2 = index + 1
				this.hangerType2 = item
			}

			if (this.prodAddCnt === this.prodAddSelectCnt) {
				this.openedPanel = undefined
				this.$log('OPEN PANEL =====>>> [23]')
			} else {
				this.activeClass = 'selected'
			}
		},

		// 상품 추가
		async addPrd() {
			this.$store.dispatch('prd/setPrdChk', true)

			if (
				this.preOrder &&
				(this.preOrder.YN_PREMON_MEM === 'Y' ||
					this.preOrder.YN_PREMON_OS === 'Y')
			) {
				// 이관요청
				this.processTransfer()
			} else {
				this.next()
			}
		},

		// 타지국에서 관리되는 계정
		async processTransfer() {
			const params = {
				salesCd: this.$store.getters['common/codyNo'], // 코디번호 코디사번
				orderNo: this.preOrder.ORDER_NO,
				// npureYm2: this.preOrder.N_PURE_YM2
				npureYm2: moment().format('YYYYMM')
			}
			const res = await postValidTransfer(params)
			let msg = res.resultObject.data.MESSAGE // '해당 렌탈 계정이 타지국에서 관리되고 있습니다.<br />이관신청 하시겠습니까?',

			if (
				res.resultObject.data.TYPE === 'F' &&
				res.resultObject.data.CODE === '4'
			) {
				const confirmRes = await this.$confirm({
					message: msg,
					okText: '이관신청',
					cancelText: '취소'
				})

				if (confirmRes) {
					const transferRes = await postTransfer(params)
					msg = transferRes.resultObject.data.MESSAGE

					if (
						transferRes.resultObject.data.TYPE === 'T' &&
						transferRes.resultObject.data.CODE === '0'
					) {
						// store 저장
						let prd = {
							oid: generateRandomHash(),
							options: {
								sellType: this.sellType, // 판매구분
								setupType: this.setupType, // 설치 유형
								freeStand: this.freeStandType, // 프리스탠드
								hangerType: this.hangerType, // 추가선택1
								hangerType2: this.hangerType2, // 추가선택2
								filterType: this.filterType, // 필터선택1
								filterType2: this.filterType2, // 필터선택2
								filterType3: this.filterType3, // 필터선택3
								filterType4: this.filterType4, // 필터선택4
								mngType: this.manageType, // 관리유형
								agreeTerm: this.agreeTermType, // 의무사용기간
								rerentalType: this.rerentalType, // 렌탈 종류
								preOrder: this.preOrder, // 이전주문번호
								preOrderItems: this.preOrderItems, // 이전주문번호 리스트
								prdOption1: this.prdOption1, //부가서비스
								prdOption2: this.prdOption2, //별매상품
								membershipType: this.membershipType, //멤버십
								reg_price: this.prd.reg_price, // 등록비
								month_amt: this.prd.month_amt, // 월 렌탈 금액
								price: this.prd.price, // 일시불 금액
								quantity: this.prdQuantity, // 상품 수량
								prdha: this.dtl01.IT_ZSDS1034[0].PRDHA, // 상품 카테고리
								returnPromotionApplied: this.returnPromotionApplied, // 미반납 재렌탈 프로모션
								returnPenaltyApplied: this.returnPenaltyApplied, //반납접수 여부
								returnPenaltyAmt: this.returnPenaltyAmt, // 반납위약금
								E_CREDIT_STATUS: this.dtl01.E_CREDIT_STATUS,
								ET_ZSDS5230: this.dtl03.ET_ZSDS5230, // 멈버십 구조체 데이터
								ET_FREE_ADD: this.dtl03.ET_FREE_ADD, // 필수 무상별매 정보
								preOwnerYm: this.isPreContract ? this.preOrder.YYYY_MM : '', // 사전계약 도래년월
								homePreOrder: this.homePreOrder, // 홈체험 주문번호
								homePreOrderItems: this.homePreOrderItems, // 홈체험 주문번호 리스트
								chairMultiFac: this.chairMultiFac // 다중이용시설 설치장소
							},
							prd: this.prd,
							esProd: this.dtl03.ES_PROD,
							E_INST_X: this.dtl03.E_INST_X
						}

						this.$store.dispatch('prd/addPrd', prd)

						// 웹 주문정보 저장
						const mobOrderNo = this.$store.getters['verify/mobOrderNo']

						fetchWebOrderInfoById(mobOrderNo).then(res => {
							let webParams = res.resultObject
							let order = JSON.parse(webParams.orderInfoJson)
							order = Object.assign(order, {
								prdList: this.$store.getters['prd/prdList']
							})
							webParams.step = '02' // 상품선택
							webParams.statusCode = '103' // 이관요청
							webParams.orderInfoJson = JSON.stringify(order)

							postWebOrderInfoById(mobOrderNo, webParams).then(postRes => {
								this.$log('postRes: ', JSON.stringify(postRes))
								// 모바일 주문현황으로 이동
								this.$router.push({ name: 'order-status' })
							})
						})
					} else {
						this.$alert({
							message: msg
						})
					}
				}
			} else {
				this.next()
			}
		},

		// 해당 지국에서 관리되는 계정
		next() {
			let prd = {
				oid: generateRandomHash(),
				options: {
					sellType: this.sellType, // 판매구분
					setupType: this.setupType, // 설치 유형
					freeStand: this.freeStandType, // 프리스탠드
					hangerType: this.hangerType, // 추가선택1
					hangerType2: this.hangerType2, // 추가선택2
					filterType: this.filterType, // 필터선택1
					filterType2: this.filterType2, // 필터선택2
					filterType3: this.filterType3, // 필터선택3
					filterType4: this.filterType4, // 필터선택4
					mngType: this.manageType, // 관리유형
					agreeTerm: this.agreeTermType, // 의무사용기간
					rerentalType: this.rerentalType, // 렌탈 종류
					preOrder: this.preOrder, // 이전주문번호
					preOrderItems: this.preOrderItems, // 이전주문번호 리스트
					prdOption1: this.prdOption1, //부가서비스
					prdOption2: this.prdOption2, //별매상품
					membershipType: this.membershipType, //멤버십
					reg_price: this.prd.reg_price, // 등록비
					month_amt: this.prd.month_amt, // 월 렌탈 금액
					price: this.prd.price, // 일시불 금액
					quantity: this.prdQuantity, // 상품 수량
					prdha: this.dtl01.IT_ZSDS1034[0].PRDHA, // 상품 카테고리
					returnPromotionApplied: this.returnPromotionApplied, // 미반납 재렌탈 프로모션
					returnPenaltyApplied: this.returnPenaltyApplied, //반납접수 여부
					returnPenaltyAmt: this.returnPenaltyAmt, // 반납위약금
					E_CREDIT_STATUS: this.dtl01.E_CREDIT_STATUS,
					ET_ZSDS5230: this.dtl03.ET_ZSDS5230, // 멈버십 구조체 데이터
					ET_FREE_ADD: this.dtl03.ET_FREE_ADD, // 필수 무상별매 정보
					preOwnerYm: this.isPreContract ? this.preOrder.YYYY_MM : '', // 사전계약 도래년월
					homePreOrder: this.homePreOrder, // 홈체험 주문번호
					homePreOrderItems: this.homePreOrderItems, // 홈체험 주문번호 리스트
					chairMultiFac: this.chairMultiFac // 다중이용시설 설치장소
				},
				prd: this.prd,
				esProd: this.dtl03.ES_PROD,
				E_INST_X: this.dtl03.E_INST_X
			}
			this.$store.dispatch('prd/addPrd', prd)
			this.reset()
		},

		async setHomePreOrderList() {
			// https://jira.nmn.io/browse/CWMJPRO-624 8종 제품(안마베드 4종, 척추베드 4종) 상품코드만 호출될 수 있도록 제한
			if (
				[
					'4100727',
					'4100726',
					'4100725',
					'4100849',
					'4100719',
					'4100717',
					'4100718',
					'4100720'
				].includes(this.prd.erp_cd)
			) {
				const homePreOrderListRes = await fetchHomePreOrderList(
					this.$store.state['verify'].customer.custNo
				)

				this.homePreOrderItems = homePreOrderListRes.resultObject.ET_LIST
			}
		},

		isUsedHomePreOrder(homePreOrder) {
			let is = false
			const prevList = this.$store.getters['prd/prdList']
			is = prevList.some(prd => {
				if (prd.options.homePreOrder) {
					is =
						prd.options.homePreOrder.HOME2_ORDER_NO ===
						homePreOrder.HOME2_ORDER_NO
				} else {
					is = false
				}
				return is
			})

			return is
		},

		/**
		 * 홈체험2 주문번호 선택
		 * @param item 선택된 홈체험2 기존주문
		 * @param index 선택된 홈체험2 기존주문 INDEX
		 * @returns {Promise<void>}
		 */
		async homePreOrderEvent(item, index) {
			this.homePreOrder = item
			this.homePreOrderSelected = index + 1
			this.openedPanel += 1
		},

		// 안마의자 제품군 설치정보 선택
		async chairMultiFacEvent(item) {
			this.chairMultiFacSelected = item
			if (item.depth === 2) {
				if (!item.install) {
					await this.$alert({
						message:
							'선택한 장소는 설치할 수 없는 장소입니다. 다중이용시설 주문 규정을 확인해 주세요.'
					})
				}
				// https://jira.nmn.io/browse/CWMJPRO-633 추후 부가서비스 등록 되면 사용될 수 있도록 주석처러
				// const msg = item.install
				// 	? '선택한 장소는 AS연장 부가서비스 가입이 가능한 다중이용시설입니다.'
				// 	: '선택한 장소는 설치할 수 없는 장소입니다. 다중이용시설 주문 규정을 확인해 주세요.'
				// await this.$alert({
				// 	message: msg
				// })

				if (!item.install) return
				// 의무사용기간 5년이 아니라면 초기화
				if (
					this.agreeTermType &&
					this.agreeTermType !== '' &&
					this.agreeTermType.USE_DUTY_YEAR !== '5'
				) {
					this.agreeTermType = ''
					this.agreeTermSelected = null
				}
			}
			this.chairMultiFac = item
			await this.getPrdOpts03()
			this.openedPanel += 1
		},

		chairMultiFacNextDepthCheck(item) {
			const res =
				this.getChairMultiFacData(`D${item.code}`).filter(v => v.isShow) || []

			return res.length > 0
		}
	}
}
</script>
